<template>
    <div style="padding: 20px;position: relative;">
        <div style="display: flex;justify-content: space-between; align-items: center;margin-bottom: 10px;">
            <div style="position: absolute;top: -41px;right: 10px;">
                <!-- <a-select style="width: 100px;margin-right: 20px;" @change="yearChange" v-model="year">
                    <a-select-option v-for="item in yearList" :key="item.value" :value="item.value">
                        {{ item.name }}
                    </a-select-option>
                </a-select> -->

                <a-select
                    v-if="isTotalSee"
                    style="width: 200px;margin-right: 20px;" @change="yearChange"
                    v-model="deptId"
                    show-search
                    :filter-option="$selectFilterOption"
                >
                    <a-select-option
                    v-for="item in organizationList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.uniqueName }}</a-select-option
                    >
                </a-select>

                <a-radio-group style="margin-right: 20px;" v-if="deptId=='1221122277233463296'" v-model="ifWis" button-style="solid" @change="typeChange">
                    <a-radio-button :value="false">
                        设计类
                    </a-radio-button>
                    <a-radio-button :value="true">
                        智慧类
                    </a-radio-button>
                </a-radio-group>

                <span style="margin-right: 20px;">部门人数: {{ total }}</span>
                <a-button type="primary" @click="$router.push(`/dataBoard/talent?id=${deptId}`)">人力资源表</a-button>
            </div>
        </div>
        <div style="display: flex;">
            <div class="card" style="width: 33.3%;margin-right: 20px;">
                <div class="title">
                    <span :style="'color:' + color[0]" @click.prevent="$router.push(`/produce/contract?id=${deptId}&year=${year}`)">合同承接</span>完成情况</div>
                <div class="tip" v-if="targetInfo.percentA">
                    <div :style="'color:' + color[0]"><span class="info">{{ targetInfo.percentA }}</span>%</div>
                </div>
                <div id="pie1" style="width: 280px;height: 200px;"></div>
                <div class="legend">
                    <div>
                        <span>承接额：</span>
                        <span v-if="ifWis">{{ pieInfo?.wisdomUndertakeAmount }}万元</span>
                        <span v-else>{{ pieInfo?.undertakeAmount }}万元</span>
                    </div>
                    <div>
                        <span>净额：</span>
                        <span v-if="ifWis">{{ pieInfo?.wisdomNetAmount }}万元</span>
                        <span v-else>{{ pieInfo?.netAmount }}万元</span>
                    </div>
                    <div>
                        <span>年度目标：</span>
                        <span v-if="ifWis">{{ pieInfo?.wisdomTargetAmount }}万元</span>
                        <span v-else>{{ pieInfo?.targetAmount }}万元</span>
                    </div>
                </div>
            </div>
            <div class="card" style="width: 33.3%;margin-right: 20px;">
                <div class="title">
                    <span :style="'color:' + color[1]" @click.prevent="$router.push(`/produce/progress?id=${deptId}&year=${year}`)">进度收入</span>完成情况</div>
                <div class="tip" v-if="targetInfo.percentB">
                    <div :style="'color:' + color[1]"><span class="info">{{ targetInfo.percentB }}</span>%</div>
                </div>
                <div id="pie2" style="width: 280px;height: 200px;"></div>
                <div class="legend">
                    <div>
                        <span>总额：</span>
                        <span v-if="ifWis">{{ pieInfo?.wisdomProjectAmount }}万元</span>
                        <span v-else>{{ pieInfo?.projectAmount }}万元</span>
                    </div>
                    <div>
                        <span>净额：</span>
                        <span v-if="ifWis">{{ pieInfo?.wisdomProjectNetAmount }}万元</span>
                        <span v-else>{{ pieInfo?.projectNetAmount }}万元</span>
                    </div>
                    <div>
                        <span>年度目标：</span>
                        <span v-if="ifWis">{{ pieInfo?.wisdomProjectTargetAmount }}万元</span>
                        <span v-else>{{ pieInfo?.projectTargetAmount }}万元</span>
                    </div>
                </div>
            </div>
            <div class="card" style="width: 33.3%;">
                <div class="title">回款完成情况</div>
                <div class="tip" v-if="targetInfo.percentC">
                    <div :style="'color:' + color[2]"><span class="info">{{ targetInfo.percentC }}</span>%</div>
                </div>
                <div id="pie3" style="width: 280px;height: 200px;"></div>
                <div class="legend">
                    <div>
                        <span>总额：</span>
                        <span v-if="ifWis">{{ pieInfo?.wisdomPaymentAmount }}万元</span>
                        <span v-else>{{ pieInfo?.paymentAmount }}万元</span>
                    </div>
                    <div>
                        <span>净额：</span>
                        <span v-if="ifWis">{{ pieInfo?.wisdomPaymentNetAmount }}万元</span>
                        <span v-else>{{ pieInfo?.paymentNetAmount }}万元</span>
                    </div>
                    <div>
                        <span>年度目标：</span>
                        <span v-if="ifWis">{{ pieInfo?.wisdomPaymentTargetAmount }}万元</span>
                        <span v-else>{{ pieInfo?.paymentTargetAmount }}万元</span>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex;margin-top: 20px;">
            <div class="card" style="width: 68%;margin-right: 20px;padding-right: 20px;padding-bottom: 20px;">
                <div class="title">月度分布情况 </div>
                <div class="barWrapper" style="display: flex;align-items: center;margin-bottom: 40px;">
                    <div style="width: 20%;font-size: 20px;text-align: center;margin-top: 40px;">
                        <!-- <div style="font-weight: bold;">{{ pieInfo?.archiveAmount }}</div> -->
                        <div style="padding-bottom: 10px;border-bottom: 1px solid #ddd;margin: 0 30px;">合同(万元)</div>
                        
                        <div style="font-weight: 400;font-size: 14px;color: #5F5F5F;text-align: center;margin-top: 10px;">人均合同(万元)</div>
                        <div style="font-size: 24px;font-weight: 600;text-align: center;">{{ staffInfo.undertakeAmountAvg }}</div>
                    </div>
                    <a-tabs style="width: 80%;" defaultActiveKey="tab1" @change="tabChange($event,'bar1')">
                        <a-tab-pane tab="表格" key="tab1">
                            <a-table :dataSource="tableList" rowKey="id" :pagination="false" :scroll="{ y:169 }">
                                <a-table-column key="month" title="月份">
                                    <template slot-scope="text, record, index">
                                        {{ text.month }}月
                                    </template>
                                </a-table-column>
                                <a-table-column key="archive" title="已签订">
                                    <template slot-scope="text, record, index">
                                        <span v-if="ifWis">{{ text.wisdomArchiveAmount }}</span>
                                        <span v-else>{{ text.archiveAmount }}</span>
                                    </template>
                                </a-table-column>
                                <a-table-column key="undertake" title="已承接">
                                    <template slot-scope="text, record, index">
                                        <span v-if="ifWis">{{ text.wisdomUndertakeAmount }}</span>
                                        <span v-else>{{ text.undertakeAmount }}</span>
                                    </template>
                                </a-table-column>
                            </a-table>
                        </a-tab-pane>
                        <a-tab-pane tab="图例" key="tab2">
                            <div id="bar1" style="width: 100%;height: 200px;"></div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
                <div class="barWrapper" style="display: flex;align-items: center;margin-bottom: 40px;">
                    <div style="width: 20%;font-size: 20px;text-align: center;margin-top: 40px;">
                        <!-- <div style="font-weight: bold;">{{ pieInfo?.projectAmount }}</div> -->
                        <div style="padding-bottom: 10px;border-bottom: 1px solid #ddd;margin: 0 30px;">进度收入(万元)</div>
                        <div style="font-weight: 400;font-size: 14px;color: #5F5F5F;text-align: center;margin-top: 10px;">人均产值(万元)</div>
                        <div style="font-size: 24px;font-weight: 600;text-align: center;">{{ staffInfo.staffAmountAvg }}</div>
                    </div>
                    <a-tabs style="width: 80%;" defaultActiveKey="tab1" @change="tabChange($event,'bar2')">
                        <a-tab-pane tab="表格" key="tab1">
                            <a-table :dataSource="tableList" rowKey="id" :pagination="false" :scroll="{ y:169 }">
                                <a-table-column key="month" title="月份">
                                    <template slot-scope="text, record, index">
                                        {{ text.month }}月
                                    </template>
                                </a-table-column>
                                <a-table-column key="archive" title="总额">
                                    <template slot-scope="text, record, index">
                                        <span v-if="ifWis">{{ text.wisdomProjectAmount }}</span>
                                        <span v-else>{{ text.projectAmount }}</span>
                                    </template>
                                </a-table-column>
                                <a-table-column key="undertake" title="净额">
                                    <template slot-scope="text, record, index">
                                        <span v-if="ifWis">{{ text.wisdomProjectNetAmount }}</span>
                                        <span v-else>{{ text.projectNetAmount }}</span>
                                    </template>
                                </a-table-column>
                            </a-table>
                        </a-tab-pane>
                        <a-tab-pane tab="图例" key="tab2">
                            <div id="bar2" style="width: 100%;height: 200px;"></div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
                <div class="barWrapper" style="display: flex;align-items: center;">
                    <div style="width: 20%;font-size: 20px;text-align: center;margin-top: 40px;">
                        <!-- <div style="font-weight: bold;">{{ pieInfo?.paymentAmount }}</div> -->
                        <div style="cursor: pointer;color: #1890ff;font-size: 14px;" @click="$router.push(`/dataBoard/editMonth?deptId=`+deptId+`&year=`+year+`&ifWis=`+ifWis)">编辑</div>
                        <div style="padding-bottom: 10px;border-bottom: 1px solid #ddd;margin: 0 30px;">回款(万元)</div>
                        <div style="font-weight: 400;font-size: 14px;color: #5F5F5F;text-align: center;margin-top: 10px;">人均回款(万元)</div>
                        <div style="font-size: 24px;font-weight: 600;text-align: center;">{{ staffInfo.paymentAmountAvg }}</div>
                    </div>
                    <a-tabs style="width: 80%;" defaultActiveKey="tab1" @change="tabChange($event,'bar3')">
                        <a-tab-pane tab="表格" key="tab1">
                            <a-table :dataSource="tableList" rowKey="id" :pagination="false" :scroll="{ y:169 }">
                                <a-table-column key="month" title="月份">
                                    <template slot-scope="text, record, index">
                                        {{ text.month }}月
                                        <a-tooltip v-if="text.month == 3" placement="top" title="“因组织架构调整，2024年1-3月设计收费按原部门23年度个人奖金占比拆分至个人，并计入新部门，统一登记在3月数据中">
                                            <a-icon type="info-circle" theme="filled" style="margin-left: 5px;color: #1890ff;"/>
                                        </a-tooltip>
                                    </template>
                                </a-table-column>
                                <a-table-column key="archive" title="总额">
                                    <template slot-scope="text, record, index">
                                        <span v-if="ifWis">{{ text.wisdomPaymentAmount }}</span>
                                        <span v-else>{{ text.paymentAmount }}</span>
                                    </template>
                                </a-table-column>
                                <a-table-column key="undertake" title="净额">
                                    <template slot-scope="text, record, index">
                                        <span v-if="ifWis">{{ text.wisdomPaymentNetAmount }}</span>
                                        <span v-else>{{ text.paymentNetAmount }}</span>
                                    </template>
                                </a-table-column>
                            </a-table>
                        </a-tab-pane>
                        <a-tab-pane tab="图例" key="tab2">
                            <div id="bar3" style="width: 100%;height: 200px;"></div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
            <div class="card" style="width: 33.3%;height: 450px;">
                <div class="title">员工管理 <span style="margin-left: 10px;color: #1890ff;font-size: 14px;" @click="$router.push(`/dataBoard/personDetail?id=${deptId}&year=${year}`)">查看详情</span></div>
                <div style="width: 100%;padding: 0 30px 0 30px">
                    <div style="display: flex;justify-content: space-between;margin-bottom: 24px;width: 100%;">
                        <div style="width: 50%;height: 125px;box-shadow: 0px 3px 8px 1px #E4E9F1;border-radius: 8px;margin-right: 16px;">
                            <div style="display: flex;justify-content: space-between;padding: 23px 47px 14px 47px;align-items: center;">
                                <img src="@/assets/dataBoard/panel1.png" style="width: 50px;height: 50px;">
                                <div style="font-weight: 600;">
                                    <span style="font-size: 24px;">{{ staffInfo.businessPeopleCount }}</span>
                                    <span style="font-size: 20px;">人</span>
                                </div>
                            </div>
                            <div style="font-weight: 400;font-size: 14px;color: #5F5F5F;text-align: center;">本月出差人次</div>
                        </div>
                        <div style="width: 50%;height: 125px;box-shadow: 0px 3px 8px 1px #E4E9F1;border-radius: 8px;">
                            <div style="display: flex;justify-content: space-between;padding: 23px 47px 14px 47px;align-items: center;">
                                <img src="@/assets/dataBoard/panel2.png" style="width: 50px;height: 50px;">
                                <div style="font-weight: 600;">
                                    <span style="font-size: 24px;">{{ staffInfo.residentPeopleCount }}</span>
                                    <span style="font-size: 20px;">人</span>
                                </div>
                            </div>
                            <div style="font-weight: 400;font-size: 14px;color: #5F5F5F;text-align: center;">本月驻场人次</div>
                        </div>
                    </div>
                    <!-- <div style="display: flex;justify-content: space-between;margin-bottom: 57px;width: 100%;">
                        <div style="width: 33.3%;box-shadow: 0px 3px 8px 1px #E4E9F1;border-radius: 8px;margin-right: 16px;">
                            <img src="@/assets/dataBoard/panel3.png" style="width: 50px;height: 50px;margin: 20px 47px;" >
                            <div style="font-size: 24px;font-weight: 600;text-align: center;margin-bottom: 10px;">{{ staffInfo.undertakeAmountAvg }}</div>
                            <div style="font-weight: 400;font-size: 14px;color: #5F5F5F;text-align: center;margin-bottom: 20px;">人均合同(万元)</div>
                        </div>
                        <div style="width: 33.3%;box-shadow: 0px 3px 8px 1px #E4E9F1;border-radius: 8px;margin-right: 16px;">
                            <img src="@/assets/dataBoard/panel3.png" style="width: 50px;height: 50px;margin: 20px 47px;" >
                            <div style="font-size: 24px;font-weight: 600;text-align: center;margin-bottom: 10px;">{{ staffInfo.staffAmountAvg }}</div>
                            <div style="font-weight: 400;font-size: 14px;color: #5F5F5F;text-align: center;margin-bottom: 20px;">人均产值(万元)</div>
                        </div>
                        <div style="width: 33.3%;box-shadow: 0px 3px 8px 1px #E4E9F1;border-radius: 8px;">
                            <img src="@/assets/dataBoard/panel3.png" style="width: 50px;height: 50px;margin: 20px 47px;" >
                            <div style="font-size: 24px;font-weight: 600;text-align: center;margin-bottom: 10px;">{{ staffInfo.paymentAmountAvg }}</div>
                            <div style="font-weight: 400;font-size: 14px;color: #5F5F5F;text-align: center;margin-bottom: 20px;">人均回款(万元)</div>
                        </div>
                    </div> -->
                </div>
                <div class="title">保证金管理 <span style="margin-left: 10px;color: #1890ff;font-size: 14px;" @click="$router.push(`/dataBoard/money`)">查看详情</span></div>
                <div style="width: 100%;padding: 0 30px 0 30px">
                    <div style="display: flex;justify-content: space-between;margin-bottom: 57px;width: 100%;">
                        <div style="width: 33.3%;box-shadow: 0px 3px 8px 1px #E4E9F1;border-radius: 8px;margin-right: 16px;">
                            <img src="@/assets/dataBoard/panel3.png" style="width: 50px;height: 50px;margin: 20px 47px;" >
                            <div style="font-size: 24px;font-weight: 600;text-align: center;margin-bottom: 10px;">{{ moneyInfo.count }}</div>
                            <div style="font-weight: 400;font-size: 14px;color: #5F5F5F;text-align: center;margin-bottom: 20px;">项目数量</div>
                        </div>
                        <div style="width: 33.3%;box-shadow: 0px 3px 8px 1px #E4E9F1;border-radius: 8px;margin-right: 16px;">
                            <img src="@/assets/dataBoard/panel3.png" style="width: 50px;height: 50px;margin: 20px 47px" >
                            <div style="font-size: 24px;font-weight: 600;text-align: center;margin-bottom: 10px;">{{ moneyInfo.payAmountSum }}</div>
                            <div style="font-weight: 400;font-size: 14px;color: #5F5F5F;text-align: center;margin-bottom: 20px;">支付金额(万元)</div>
                        </div>
                        <div style="width: 33.3%;box-shadow: 0px 3px 8px 1px #E4E9F1;border-radius: 8px;">
                            <img src="@/assets/dataBoard/panel3.png" style="width: 50px;height: 50px;margin: 20px 47px;" >
                            <div style="font-size: 24px;font-weight: 600;text-align: center;margin-bottom: 10px;">{{ moneyInfo.collectedAmountSum }}</div>
                            <div style="font-weight: 400;font-size: 14px;color: #5F5F5F;text-align: center;margin-bottom: 20px;">待回收金额(万元)</div>
                        </div>
                    </div>
                </div>
                <!-- <a-table style="margin: 10px 60px 20px 60px;" bordered :data-source="tableList" :pagination="false" rowKey="id" :scroll="{ y: 900 }">
                    <a-table-column width="50px" align="center">
                        <template slot-scope="text, row, index">
                            <img v-if="index == 0" src="@/assets/top1.png" style="width: 28px;height: 28px">
                            <img v-else-if="index == 1" src="@/assets/top2.png" style="width: 28px;height: 28px">
                            <img v-else-if="index == 2" src="@/assets/top3.png" style="width: 28px;height: 28px">
                            <div v-else style="width: 20px;height: 20px;background: #F0F2F5;border-radius: 50%;margin: auto;">{{ index + 1 }}</div>
                        </template>
                    </a-table-column>
                    <a-table-column key="userName" title="姓名" data-index="userName"></a-table-column>
                    <a-table-column key="amount" title="产值">
                        <template slot-scope="text, record, index">
                            {{ text.amount }}万元
                        </template>
                    </a-table-column>
                </a-table> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {formatAmount} from "@/utils/dataBoard"
import * as echarts from "echarts/core";
import request from "@/api/request";
import {
    TooltipComponent,
    GridComponent,
    LegendComponent,
} from "echarts/components";
import { BarChart, LineChart, PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

function fetchList(year,deptId) {
    return request({
        url: "project-service/dsContract/dept/" + year + "/" + deptId,
    });
}
function fetchNum(params) {
    return request({
        url: "project-service/dsEmployee/count",
        params: params
    });
}
function getOrganization() {
    return request({
        url: "user-service/org/listForPrd",
    });
}
echarts.use([
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    LineChart,
    PieChart,
    CanvasRenderer,
]);
export default {
    data() {
        return {
            moneyInfo: {},
            staffInfo:{},
            total: 0,
            title: '',
            tableList: [],
            dialogShow: false,
            color: ['#256DFF', '#25D7D8', '#25D86F', '#FFA700'],
            targetInfo: {
                percentA: '',
                percentB: '',
                percentC: '',
                percentD: '',
            },
            year: 0,
            deptId: '',
            yearList: [],
            lastMonth: '',
            pieInfo:{},
            sumA: 0,
            sumB: 0,
            sumC: 0,
            ifWis: false,
            organizationList: []
        };
    },
    computed: {
        ...mapState("auth", ["user"]),
        ...mapGetters("auth", ["isDeptSee", "isTotalSee"]),
    },
    mounted() {
        this.getYearList()
        
        getOrganization().then(v=>{
            this.organizationList = v
            if(this.isDeptSee){
                this.deptId = this.user.employeeVo.deptId
            }else{
                this.deptId = this.organizationList[0].id
            }
            this.getData()
            fetchNum({deptId:this.deptId}).then(res => {
                this.total = res
            })
        })
        
    },

    methods: {
        typeChange(data) {
            this.ifWis = data.target.value
            if(this.ifWis){
                this.getData(true)
            }else{
                this.getData(false)
            }
        },
        getData(ifWis) {
            fetchList(this.year, this.deptId).then(res => {
                res = formatAmount(res)
                this.pieInfo = res.yearData;
                this.staffInfo = res.yearData;
                this.moneyInfo = res.bondDeptVo;
                this.renderTarget(res.yearData, ifWis)
                this.tableList = res.monthHis
            })
        },
        renderTarget(data, ifWis) {
            if (!data) {
                this.pie1 && this.pie1.clear()
                this.pie2 && this.pie2.clear()
                this.pie3 && this.pie3.clear()
                this.targetInfo = {
                    percentA: '',
                    percentB: '',
                    percentC: '',
                }
                return
            }
            if(ifWis){
                this.targetInfo.percentA = data.wisdomTargetAmount ? (data.wisdomUndertakeAmount / data.wisdomTargetAmount*100).toFixed(0) : 0
                this.targetInfo.percentB = data.wisdomProjectTargetAmount ? (data.wisdomProjectAmount / data.wisdomProjectTargetAmount*100).toFixed(0) : 0
                this.targetInfo.percentC = data.wisdomPaymentTargetAmount ? (data.wisdomPaymentAmount / data.wisdomPaymentTargetAmount*100).toFixed(0) : 0
            }else{
                this.targetInfo.percentA = data.targetAmount ? (data.undertakeAmount / data.targetAmount*100).toFixed(0) : 0
                this.targetInfo.percentB = data.projectTargetAmount ? (data.projectAmount / data.projectTargetAmount*100).toFixed(0) : 0
                this.targetInfo.percentC = data.paymentTargetAmount ? (data.paymentAmount / data.paymentTargetAmount*100).toFixed(0) : 0
            }
            
            const list = [
                { name: "年度目标", value: ifWis? data.wisdomTargetAmount - data.wisdomUndertakeAmount: data.targetAmount - data.undertakeAmount },
                { name: "已承接", value: ifWis? data.wisdomUndertakeAmount : data.undertakeAmount },
            ]
            let option = {
                color: ["#e0e0e0", this.color[0]],
                series: [
                    {
                        center: ['50%', '50%'],
                        name: "",
                        type: "pie",
                        radius: ['40%', '50%'],
                        label: {
                            show: false,
                        },
                        data: list,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };
            this.pie1 = echarts.init(document.getElementById("pie1"));
            this.pie1.setOption(option);

            option.color[1] = this.color[2]
            option.series[0].data = [
                { name: "年度目标", value: ifWis? data.wisdomProjectTargetAmount - data.wisdomProjectAmount:data.projectTargetAmount - data.projectAmount },
                { name: "总额", value: ifWis? data.wisdomProjectAmount:data.projectAmount },
                
            ]
            this.pie2 = echarts.init(document.getElementById("pie2"));
            this.pie2.setOption(option);

            option.color[1] = this.color[3]
            option.series[0].data = [
                { name: "年度目标", value: ifWis? data.wisdomPaymentTargetAmount - data.wisdomPaymentAmount:data.paymentTargetAmount - data.paymentAmount },
                { name: "总额", value: ifWis? data.wisdomPaymentAmount:data.paymentAmount },
            ]
            this.pie3 = echarts.init(document.getElementById("pie3"));
            this.pie3.setOption(option);
        },
        renderMonthHisData(data, id) {
            // if (!data) {
            //     this.bar1 && this.bar1.clear()
            //     this.bar2 && this.bar2.clear()
            //     this.bar3 && this.bar3.clear()
            //     return
            // }
            let option = {
                grid: {
                    left: '2%',
                    right: '2%',
                    bottom: '2%',
                    top: '20%',
                    containLabel: true //可容纳
                },
                legend: {
                },
                tooltip: {
                    trigger: 'axis',
                },
                xAxis: {
                    type: 'category',
                    data: data.map(v => v.month + '月')
                },
                yAxis: {
                    type: 'value',
                    name: "(万元)",
                },
                series: [{
                    name: '已签订',
                    color: this.color[0],
                    data: this.ifWis ? data.map(v => v.wisdomArchiveAmount):data.map(v => v.archiveAmount),
                    type: 'line',
                    barGap: '20%',
                    barCategoryGap: '40%',
                    itemStyle: {
                        normal: {
                            barBorderRadius: [50, 50, 0, 0],
                        }
                    }
                }, {
                    name: '已承接',
                    color: this.color[1],
                    data: this.ifWis ? data.map(v => v.wisdomUndertakeAmount):data.map(v => v.undertakeAmount),
                    type: 'line',
                    barGap: '20%',
                    barCategoryGap: '40%',
                    itemStyle: {
                        normal: {
                            barBorderRadius: [50, 50, 0, 0],
                        }
                    }
                }]
            };
            if(id == 'bar1'){
                this.bar1 = echarts.init(document.getElementById("bar1"));
                this.bar1.setOption(option);
            }    

            if(id == 'bar2'){
                option.series[0].name = '总额'
                option.series[0].data = this.ifWis ? data.map(v => v.wisdomProjectAmount):data.map(v => v.projectAmount)
                option.series[1].name = '净额'
                option.series[1].data = this.ifWis ? data.map(v => v.wisdomProjectNetAmount):data.map(v => v.projectNetAmount)
                this.bar2 = echarts.init(document.getElementById("bar2"));
                this.bar2.setOption(option);
            }  

            if(id == 'bar3'){
                option.series[0].name = '总额'
                option.series[0].data = this.ifWis ? data.map(v => v.wisdomPaymentAmount):data.map(v => v.paymentAmount)
                option.series[1].name = '净额'
                option.series[1].data = this.ifWis ? data.map(v => v.wisdomPaymentNetAmount):data.map(v => v.paymentNetAmount)
                this.bar3 = echarts.init(document.getElementById("bar3"));
                this.bar3.setOption(option);
            }
        },
        tabChange(e, id) {
            console.log(e)
            if(e == 'tab2') {
                this.$nextTick(()=>{
                    this.renderMonthHisData(this.tableList,id)
                })
                
            }
        },
        getYearList() {
            const now = new Date().getFullYear()
            console.log(now)
            for (let index = 2021; index <= now; index++) {
                this.yearList.push(
                    {
                        name: index,
                        value: index
                    }
                )
            }
            this.year = this.yearList[this.yearList.length - 1].value
        },
        yearChange() {
            this.ifWis = false
            this.getData()
            fetchNum({deptId:this.deptId}).then(res => {
                this.total = res
            })
        }
    }
};
</script>
<style lang="less" scoped>
.card {
    background: #FFFFFF;
    border-radius: 0px 0px 2px 2px;
    position: relative;
    .tip {
        text-align: center;
        font-size: 12px;
        color: #595959;
        position: absolute;
        top: 50%;
        left: 17%;
        width: 100px;
        margin: auto;

        .info {
            font-weight: bold;
            font-size: 30px;
        }
    }
    .legend{
        position: absolute;
        top: 40%;
        right: 100px;
        font-size: 16px;
        color: #8C8C8C;
        >div{
            margin-bottom: 20px;
            span{
                margin-right: 10px;
            }
            :first-child{
                width: 80px;
                display: inline-block;
            }
            :last-child{
                font-weight: bold;
                font-size: 20px;
                color: rgba(0,0,0,0.65);
                margin-left: 10px;
            }
        }
    }
}

.item {
    width: 25%;

    margin-right: 10px;
    text-align: center;
    position: relative;

    .header {
        margin-top: 20px;
        font-size: 16px;

        span {
            cursor: pointer;
        }
    }

    .tip {
        font-size: 12px;
        color: #595959;
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        margin: auto;

        .info {
            font-weight: bold;
            font-size: 30px;
        }
    }

    .pie {
        width: 100%;
        height: 250px;
    }
}

.title {
    padding: 10px 0 10px 30px;
    font-size: 20px;
    font-weight: bold;
    span {
        cursor: pointer;
    }
}

.title::before {
    content: '.';
    display: inline-block;
    line-height: 17px;
    width: 5px;
    height: 17px;
    margin-right: 12px;
    background: #1890FF;
    color: #1890FF;
    border-radius: 3px;
}
</style>